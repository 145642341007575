<template>
  <div class="w-100">
    <div class="global-green flex ac-c center-xs pt-30 pb-10" >
      <div class="flex w-100 jc-c">
        <h2 class="col-xs-12 mt-30 mb-15">Recent Results</h2>
        <h3 class="col-xs-12 mb-30">Overview of recent results from Strike benchmark or custom tests</h3>
        <div class="flex w-90 list-max-width">
          <list origin="results" :listItems="data.results"></list>
        </div>
      </div>
<!--      <div class="button-small-more col-xs-2 col-md-1 mt-15 mb-30 pl-10 pr-10">Filter</div>-->
      <div class="button-small-more col-xs-2 col-md-1 mt-15 mb-30 ml-15 pl-10 pr-10" @click="show.showAllVisible = true">Show all</div>
    </div>
    <div class="flex w-100 center-xs mt-30 mb-30">
      <div class="flex col col-xs-8 col-md-5">
        <h2 class="mt-30 mb-15">Search</h2>
        <h3 class="mb-30">Search for strike results by Benchmark ID, Device or Benchmark Types</h3>
        <van-search placeholder="benchmark id, device or benchmark type" class="w-100"/>
        <div class="button-solid-green-70 flex jc-c ai-c mt-30 mb-30"  @click="searchResults">
          <span>Go</span>
        </div>
      </div>
    </div>
    <div class="global-blue flex col ac-c center-xs pt-20" >
      <h2 class="mt-30 mb-15">Top Results</h2>
      <h3 class="mb-30">Your best Strike results on all devices</h3>
      <result-list class="w-100 mb-30 pb-30 col-xs-10" path='users/me/results/top'></result-list>
    </div>
  </div>
  <show-all-popup v-if="show.showAllVisible" :data="show.data" about="results" @close="show.showAllVisible = false"></show-all-popup>
</template>
<script setup>
import {List, ResultList, ShowAllPopup} from '@/components';
import { get } from "@/lib/api";
import { reactive, onBeforeMount, inject } from 'vue';
import { Notify  } from 'vant';

const data = reactive({
    results: []
})

const maxTilesInList = inject('maxTilesInList')
const show = reactive({
  showAllVisible: false,
  about: '',
  data: []
})

onBeforeMount(async() => {
    try {
        const res = await get('users/me/results', true)
        show.data = res.data
        data.results= show.data.length > maxTilesInList ? show.data.slice(0, maxTilesInList) : show.data
    }
    catch(error) {
        console.error("dataFetchError : " + error)
    }
})

const disableNonMVPFeatures = inject('disableNonMVPFeatures')
function searchResults(){
  disableNonMVPFeatures ? showNotify() : '' //Handle search
}

const infoMessage = inject('disabledFeaturesMessage')
function showNotify() {
  Notify ({type: 'warning', message: infoMessage, duration:1500})
}
</script>